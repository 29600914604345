

$(document.body)
    .on("gdpr:show", function() {
        console.log("Cookie dialog is shown");
    })
    .on("gdpr:accept", function() {
        var preferences = $.gdprcookie.preference();
        if ($.gdprcookie.preference("analytics") === false) {
			
			removeAnalytics();
			setTimeout(function() {
				deleteCookie('_gat_gtag_UA_49924973_7');
				deleteCookie('_ga');
				deleteCookie('_gid');
			}, 1000);
		}
	
		if ($.gdprcookie.preference("analytics") === true) {
			console.log("This should run because marketing is accepted.");
			addAnalytics();
		}
    })
    .on("gdpr:advanced", function() {
        console.log("Advanced button was pressed");
    });

$(document).ready(function () {

	$.gdprcookie.init({
		title: "Aceptar cookies",
		message: "Esta web utiliza cookies de terceros para recoger datos de visita, puedes cambiar la configuración desde opciones de cookies</a>",
		delay: 600,
		expires: 30,
		acceptBtnLabel: "Aceptar cookies",
		advancedBtnLabel: "Opciones de cookies",
		subtitle: "Selecciona las cookies que quieres aceptar:",
		acceptReload: false
	});

	var preferences = $.gdprcookie.preference();
	
	console.log('analytics:'+$.gdprcookie.preference("analytics"));
	
    if ($.gdprcookie.preference("analytics") === false) {
        console.log('entrado');
		setTimeout(function() {
			deleteCookie('_gat_gtag_UA_49924973_7');
			deleteCookie('_ga');
			deleteCookie('_gid');
		}, 1000);
	}

	if ($.gdprcookie.preference("analytics") === true) {
		console.log("This should run because marketing is accepted.");
		addAnalytics();
	}
});

function setCookie(cname, cvalue, exdays) {
    var d = new Date();
    d.setTime(d.getTime() + (exdays*24*60*60*1000));
    var expires = "expires="+ d.toUTCString();
    document.cookie = cname + "=" + cvalue + "; " + expires;
}

function getCookie(cname) {
    var name = cname + "=";
    var ca = document.cookie.split(';');
    for(var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length,c.length);
        }
    }
    return -1;
}

function deleteCookie(cname){
    var cvalue = getCookie( cname );
    setCookie(cname, cvalue, 0);
}

function removeAnalytics() {
	$("#ga_cdn").remove();
	$("#ga_script").remove();
}
function addAnalytics() {
	$("footer").append(`
	<!-- Global site tag (gtag.js) - Google Analytics -->
	<script id="ga_cdn" async src="https://www.googletagmanager.com/gtag/js?id=UA-49924973-7"></script>
	<script id="ga_script">
	  window.dataLayer = window.dataLayer || [];
	  function gtag(){dataLayer.push(arguments);}
	  gtag('js', new Date());
	
	  gtag('config', 'UA-49924973-7');
	</script>
	
	`);

	eval($("#ga_script").text);
}
$("#specialty").on("change", function () {
	let val = $(this).val();

	if (val == "otra") {
		$("#other_specialty_row").removeClass("d-none");
		$("#other_specialty").attr("required", true);
	} else {
		$("#other_specialty_row").addClass("d-none");
		$("#other_specialty").attr("required", false);
	}
});